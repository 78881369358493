import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const Albumblock = ({ album }) => {
    return (
            <Link to={`/albums/${album.node.slug}`}>
                <div className="bg-[#262626] max-w-sm p-4 text-[#e1e1e1] hover:border-2 hover:border-gray-500 border-2 border-transparent">
                <GatsbyImage image={album.node.cover.gatsbyImageData} alt={album.node.title} />
                    <p className='text-xl mt-4'>{album.node.title} ({album.node.year})</p>
                </div>
            </Link>        
    )
}

export default Albumblock
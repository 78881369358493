import Albumblock from '../components/albumblock'
import * as React from 'react'
import Layout from '../components/layout'
import { graphql, useStaticQuery } from 'gatsby'

const IndexPage = () => {
  const data = useStaticQuery(allAlbumQuery).allContentfulAlbum.edges

  return (
    <Layout pageTitle="Home Page">
      <div className='flex flex-wrap justify-center columns-3 gap-4 text-center'>

        {data.map((album) => (
          <Albumblock album={album} key={album.title} />
        ))}
      </div>
    </Layout>
  )
}

export const allAlbumQuery = graphql`
query {
  allContentfulAlbum(sort:{fields:year,order:DESC}) {
    edges {
      node {
          id
        title
        slug
        credits {
          raw
        }
        description {
          raw
        }
        cover {
          gatsbyImageData(width:400)
        }
        year
        songs {
          title
          testLyrics {
            raw
          }
        }
      }
    }
  }
  }
  `

export default IndexPage